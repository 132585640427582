<template>
  <div>
    <div class="d-flex flex-md-row flex-column">
      <vx-button
        variant="primary"
        :loading="loading"
        icon="SearchIcon"
        class="mb-1 mr-1"
        @click="checkSkins"
      >
        Check Skins
      </vx-button>
      <b-alert show>
        {{ alertMessage }}
      </b-alert>
    </div>
    <b-row>
      <b-col cols="12" md="6">
        <skins-import-table
          :skins="skinsToCreate"
          :resource="skinsCreateResource"
          title="Create skins"
        />
      </b-col>
      <b-col cols="12" md="6">
        <skins-import-table
          :skins="skinsToUpdate"
          :resource="skinsUpdateResource"
          title="Update skins"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { skins, useResource } from '@/services/resources'
import { ref } from '@vue/composition-api'
import VxButton from '@/components/buttons/VxButton'
import { BAlert, BCol, BRow } from 'bootstrap-vue'
import { isEmpty } from 'lodash'
import SkinsImportTable from '@/views/skins/SkinsImportTable'

export default {
  name: 'SkinsImport',
  components: {
    SkinsImportTable,
    VxButton,
    BAlert,
    BRow,
    BCol
  },
  setup () {
    const {
      loading,
      callRequest: checkNewRequest
    } = useResource(skins.checkNew)

    const skinsToCreate = ref([])
    const skinsCreateResource = skins.create

    const skinsToUpdate = ref([])
    const skinsUpdateResource = skins.update

    const messages = {
      initial: 'Press "Check Skins" to update. It takes some time.',
      empty: 'There are no skins to import. Press "Check Skins" to update. It takes some time.'
    }
    const alertMessage = ref(messages.initial)

    const checkSkins = async () => {
      const [err, res] = await checkNewRequest()
      if (err) return

      const { data: { new_skins, new_skins_images } } = res

      skinsToCreate.value = new_skins
      skinsToUpdate.value = new_skins_images

      alertMessage.value = isEmpty(new_skins) && isEmpty(new_skins_images)
        ? messages.empty
        : messages.initial
    }

    return {
      loading,
      checkSkins,
      alertMessage,

      skinsToCreate,
      skinsCreateResource,

      skinsToUpdate,
      skinsUpdateResource
    }
  }
}
</script>
