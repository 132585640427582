<template>
  <vx-card no-body>
    <template #title>
      {{ title }}
    </template>
    <template #actions>
      <vx-button
        variant="primary"
        :can="can"
        :disabled="!selectedItems.length"
        :loading="loading"
        @click="saveSelected"
      >
        Save selected
      </vx-button>
    </template>

    <b-card-body>
      <vx-table-simple
        :items="items"
        :columns="columns"
        :per-page="100"
        :filters="filters"
        :filter-input-width="6"
        primary-key="market_hash_name"
        has-pagination
        hide-per-page
        selectable
        @row-selected="onRowSelected"
      />
    </b-card-body>
  </vx-card>
</template>

<script>
import {
  VxTableSimple,
  VxCellImage,
  VxCellSelectableCheckbox,
  VxHeadCellSelectableCheckbox,
  VxCellRarityBadge
} from '@/components/table'
import { ref, watch } from '@vue/composition-api'
import VxButton from '@/components/buttons/VxButton'
import { map } from 'lodash'
import { categories, useResource } from '@/services/resources'
import { useToast } from '@/plugins/toastification'
import VxCard from '@/components/VxCard'
import { BCardBody } from 'bootstrap-vue'
import { filterTypes } from '@/services/table'

export default {
  name: 'SkinsImportTable',
  components: {
    VxButton,
    VxTableSimple,
    VxCard,
    BCardBody
  },
  props: {
    title: {
      type: String,
      required: true
    },
    skins: {
      type: Array,
      required: true
    },
    resource: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const toast = useToast()

    const { can, loading, callRequest } = useResource(props.resource)

    const selectedItems = ref([])
    const items = ref([])

    watch(() => props.skins, (value) => {
      items.value = value
    })

    const columns = [
      {
        key: 'selected',
        component: VxCellSelectableCheckbox,
        headComponent: VxHeadCellSelectableCheckbox
      },
      {
        key: 'image',
        component: VxCellImage
      },
      { key: 'market_hash_name' },
      {
        key: 'rarity',
        component: VxCellRarityBadge
      }
    ]

    const filters = [
      {
        key: 'market_hash_name',
        type: filterTypes.text
      },
      {
        key: 'rarity',
        type: filterTypes.serverSelect,
        resource: categories.getBaseInfo,
        resourceMapper: ({ rarity }) => rarity,
        optionsLabel: 'full',
        reduce: ({ short }) => short
      }
    ]

    const onRowSelected = (items) => {
      selectedItems.value = items
    }

    const saveSelected = async () => {
      const names = map(selectedItems.value, 'market_hash_name')

      const [err] = await callRequest({ data: { skins: names } })
      if (err) {
        toast.error(err.data.message)
        return
      }

      toast.success('Skins successfully updated')

      items.value = items.value.filter(({ market_hash_name }) => !names.includes(market_hash_name))
    }

    return {
      items,
      columns,
      filters,
      selectedItems,

      onRowSelected,

      can,
      loading,
      saveSelected
    }
  }
}
</script>
